<div class="notification-message white-text mat-body-1">
  <div class="notification-message_header">
    <i *ngIf="data.type === 'success'" class="material-icons mat-icon-style">done</i>
    <i *ngIf="data.type === 'warning'" class="material-icons mat-icon-style">warning</i>
    <i *ngIf="data.type === 'danger'" class="material-icons mat-icon-style">error_outline</i>
    <span class="custom-font">{{data.heading}}</span>
    <span class="notification-message_header-action">
      <a><i (click)="closeDialog()" class="material-icons svg-color">clear</i></a>
    </span>
  </div>
  <div class="notfication-body-padding">
    <span [innerHTML]="data.body"></span>
  </div>
</div>