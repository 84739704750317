export const environment = {
  production: true,
  release: 'staging',
  apiUrl: 'https://bddcstaging.bitcube.co.za/customer_api/v1',
  base: 'https://bddcportal.bitcube.tech/',
  facebook_app_id: '798644507270370',
  social_share_base_url: 'https://bddcportal.bitcube.tech',
  bug_snag_api_key: 'cf95d085d74a8e5e99c1bba160df5657',
  google_analytics_id: 'G-JQ89JMV4W1'
};
